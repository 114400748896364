<template>
  <div class="display-flex calendar-wrapper">
    <div
      class="calendar-navi__wrapper calendar-navi__left"
      :class="{ disabled: disabledLeftArrow }"
      v-show="!isMobile"
      @click="toPreviousDate"
    >
      <svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.91016 1.41L3.33016 6L7.91016 10.59L6.50016 12L0.500156 6L6.50016 -6.16331e-08L7.91016 1.41Z"
          fill="none"
        />
      </svg>
    </div>
    <div class="calendar-blur left" v-show="isMobile"></div>
    <div class="calendar-container" ref="calendarContent">
      <div
        class="calendar-box"
        :class="{ selected: o.selected }"
        v-for="(o, index) in calendar"
        :key="index"
        @click="selectMatchByDate(o)"
      >
        <span class="calendar-box__week">{{ $t(o.day) }}</span>
        <span class="calendar-box__monnth">{{ o.date }} {{ $t(o.month) }}</span>
      </div>
    </div>
    <div class="calendar-blur right" v-show="isMobile"></div>
    <div
      class="calendar-navi__wrapper calendar-navi__right"
      :class="{ disabled: disabledRightArrow }"
      v-show="!isMobile"
      @click="toNextDate"
    >
      <svg
        width="8"
        height="13"
        viewBox="0 0 8 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.0898437 10.8849L4.66984 6.29492L0.0898433 1.70492L1.49984 0.294922L7.49984 6.29492L1.49984 12.2949L0.0898437 10.8849Z"
          fill="none"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import config from "@/js/config.js";
import moment from "moment";
import { mapGetters } from "vuex";
const TODAY = "TODAY";
// let before_start_date = false;
// let after_end_date = false;
export default {
  props: {
    params: {
      type: String,
    },
    show: {
      type: Number,
      default: 7,
    },
    // confirmStartDate: {
    //   type: [String, Object],
    // },
    // confirmEndDate: {
    //   type: [String, Object]
    // }
  },
  watch: {
    params: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal)
          this.selectMatchByDate(newVal, false);
      }
    },
    // confirmStartDate: {
    //   handler(newVal, oldVal) {
    //     this.init();
    //   }
    // }
  },
  computed: {
    ...mapGetters([
      "isMobile",
    ]),
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      dateList: [],
      calendar: [],
      highlightedDateIndex: 0,
      leftEdgeDateIndex: 0,
      rightEdgeDateIndex: 0,
      cardMarginRight: 0,
      cardMarginLeft: 0,
      cardWidth: 0,
      disabledLeftArrow: false,
      disabledRightArrow: false,

      currentStartDate: null,
      currentSelectedDate: null,
    };
  },
  methods: {
    init() {
      this.getCalendar();
    },
    setNewSelectedDate(fullDate) {
      this.currentStartDate = moment(fullDate).subtract(Math.floor(this.show / 2), "days").format("YYYY/M/D");
      this.currentSelectedDate = moment(fullDate).format("YYYY/M/D");
    },
    /**
     * @param: emit - true if user clicked on particular calendar card, 
     *         false if triggered by WC Stage selector change
     */
    selectMatchByDate(o, emit = true) {
      let theDate = typeof o === "object" ? o.fullDate : o;
      let dateArr = theDate.split("/");
      this.setNewSelectedDate(theDate);
      
      // this.calendar.forEach(card => {
      //   if (card.fullDate == o) {
      //     card.selected = true;
      //     this.highlightedDateIndex = o.index;
      //   } else {
      //     card.selected = false;
      //   }
      // });
      if (emit) {
        this.$emit("weeklyDateChange", o);
      }
      if (!this.isMobile) {

      }
      this.getCalendar(`${dateArr[0]}/${parseInt(dateArr[1])}/${dateArr[2]}`);
      // this.initCalendar();
    },
    toPreviousDate() {
      let prevDate = moment(this.dateList[0]).subtract(1, "days").format("YYYY/M/D");

      this.dateList.pop();
      this.dateList.unshift(prevDate);
      this.processTheCalendar(null, true);
    },
    toNextDate() {
      let lastDate = this.dateList[this.dateList.length - 1];
      let nextDate = moment(lastDate).add(1, "days").format("YYYY/M/D");

      this.dateList.shift();
      this.dateList.push(nextDate);
      this.processTheCalendar(null, true);
    },
    disableLeftArrow() {
      if (this.leftEdgeDateIndex === 0) {
        this.disabledLeftArrow = true;
      }
      this.disabledRightArrow =
        this.rightEdgeDateIndex < this.dateListLen ? false : true;
    },
    disableRightArrow() {
      if (this.rightEdgeDateIndex === this.dateListLen - 1) {
        this.disabledRightArrow = true;
      }
      this.disabledLeftArrow = this.leftEdgeDateIndex > 0 ? false : true;
    },
    getCalendar(userSelectedDate = null) {
      let selectedFullDate = new Date(); //2022,10,30

      if (this.currentStartDate === null) {
        this.setNewSelectedDate(`${selectedFullDate.getFullYear()}/${selectedFullDate.getMonth() + 1}/${selectedFullDate.getDate()}`);
      }

      let startDate = new Date(this.currentStartDate);
      this.dateList = [];
      let i = 0;
      while (i < this.show) {
        let newDate = startDate.getFullYear() + "/" + (startDate.getMonth() + 1) + "/" + startDate.getDate();
        this.dateList.push(newDate);
        startDate.setDate(startDate.getDate() + 1);
        i++;
      }
      this.dateListLen = this.dateList.length;

      this.processTheCalendar(userSelectedDate);
      
      if (this.isMobile) {
        this.initCalendar();
      }
    },
    processTheCalendar(userSelectedDate = null, prevNextAction = false) {
      let todayFullDate = new Date(); //2022,10,30
      let todayFullDateObj = new Date(todayFullDate.getFullYear(), todayFullDate.getMonth(), todayFullDate.getDate());
      
      this.calendar = this.dateList.map((date, i) => {
        let newFullDateObj = new Date(date);
        let humanDateMonth = newFullDateObj.getMonth() + 1;
        let humanDateDate = newFullDateObj.getDate();

        if (humanDateMonth < 10) {
          humanDateMonth = `0${humanDateMonth}`;
        }
        if (humanDateDate < 10) {
          humanDateDate = `0${humanDateDate}`;
        }

        let dateObj = {
          day: this.$tools.getDay(newFullDateObj.getDay()),
          month: this.$tools.getMonth(newFullDateObj.getMonth()),
          monthIndex: newFullDateObj.getMonth(),
          date: newFullDateObj.getDate(),
          fullDate: `${newFullDateObj.getFullYear()}-${humanDateMonth}-${humanDateDate}`,
          selected: false,
          index: i,
        };
        
        let selectedDateArr = this.currentSelectedDate.split("/");
        let currentSelectedDateObj = new Date(selectedDateArr[0], parseInt(selectedDateArr[1]) - 1, selectedDateArr[2]);

        if (userSelectedDate == null) {
          if (
            newFullDateObj.valueOf() === currentSelectedDateObj.valueOf() && 
            !prevNextAction
          ) {
            dateObj.day = TODAY;
            dateObj.selected = true;
          }
          if (prevNextAction) {
            if (newFullDateObj.valueOf() === todayFullDateObj.valueOf()) {
              dateObj.day = TODAY;
            }
            if (
              newFullDateObj.valueOf() === todayFullDateObj.valueOf() &&
              newFullDateObj.valueOf() === currentSelectedDateObj.valueOf()
            ) {
              dateObj.selected = true;
            } else if (
              newFullDateObj.valueOf() === todayFullDateObj.valueOf() &&
              newFullDateObj.valueOf() !== currentSelectedDateObj.valueOf()
            ) {
              dateObj.selected = false;
            }
            if (newFullDateObj.valueOf() === currentSelectedDateObj.valueOf()) {
              dateObj.selected = true;
            }
          }
          this.highlightedDateIndex = i;
        } else if (userSelectedDate != null) {
          let highlightedDate = false;
          if (
            (newFullDateObj.valueOf() === currentSelectedDateObj.valueOf() &&
            todayFullDateObj.valueOf() !== currentSelectedDateObj.valueOf() &&
            !highlightedDate) ||
            (newFullDateObj.valueOf() === currentSelectedDateObj.valueOf() &&
            todayFullDateObj.valueOf() === currentSelectedDateObj.valueOf() &&
            !highlightedDate)
          ) {
            dateObj.selected = true;
            highlightedDate = true;
          }
          if (newFullDateObj.valueOf() === todayFullDateObj.valueOf()) {
            dateObj.day = TODAY;
          }
        }
        
        return dateObj;
      });
    },
    initCalendar() {
      this.$nextTick(() => {
        let naviArrows = document.getElementsByClassName(
          "calendar-navi__wrapper"
        );
        let selectedCard = document.getElementsByClassName(
          "calendar-box selected"
        )[0];
        if (selectedCard != undefined) {
          this.cardMarginRight = parseFloat(
            window
              .getComputedStyle(selectedCard)
              .getPropertyValue("margin-right")
              .split("px")[0]
          );
          this.cardMarginLeft = parseFloat(
            window
              .getComputedStyle(selectedCard)
              .getPropertyValue("margin-left")
              .split("px")[0]
          );
          this.cardWidth =
            this.cardMarginRight +
            this.cardMarginLeft +
            selectedCard.getBoundingClientRect().width;
          let scrollToXPosition;
          let cardAmountToShow = parseInt(this.show);

          // if (this.dateListLen <= this.show) {
          //   for (const ele of naviArrows) {
          //     ele.style.display = "none";
          //   }
          // } else {
          //   for (const ele of naviArrows) {
          //     ele.style.display = "flex";
          //   }
          // }

          if (cardAmountToShow % 2 === 0) {
            // EVEN
            scrollToXPosition =
              selectedCard.offsetLeft -
              this.cardMarginLeft -
              (cardAmountToShow / 2 - 1) * this.cardWidth;
          } else {
            // ODD
            let theCardAmountToShow = this.isMobile ? 5 : cardAmountToShow;

            scrollToXPosition =
              selectedCard.offsetLeft -
              this.cardMarginLeft - 
              Math.floor(theCardAmountToShow / 2) * this.cardWidth;
          }
          
          // this.disableLeftArrow();
          // this.disableRightArrow();

          let clientRect = selectedCard.getBoundingClientRect();

          this.$refs.calendarContent.style.width =
            this.cardWidth * this.show + "px";
          // this.$refs.calendarContent.style.overflow = "hidden";
          this.$refs.calendarContent.scrollTo(scrollToXPosition, 0);//scrollToXPosition // clientRect.right / 2
        } 
      });
    },
  },
};
</script>

<style scoped>
.calendar-container {
  display: flex;
  position: relative;
  /* border-bottom: .1rem solid var(--color-theme); */
}
.calendar-navi__wrapper {
  display: flex;
  align-items: center;
}
.calendar-navi__wrapper.disabled svg path {
  fill: var(--color-theme);
}
.calendar-navi__left {
  padding: 0.5rem 0.875rem 0.5rem 0.5rem;
  cursor: pointer;
}
.calendar-navi__right {
  padding: 0.5rem 0.5rem 0.5rem 0.875rem;
  cursor: pointer;
}
.calendar-navi__wrapper svg path {
  fill: var(--bg-selected);/*--color-theme-2*/
}
.calendar-box {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 0.66rem;
  padding: 0.3rem 0.125rem 0.5rem 0.125rem;
  margin: 0.3rem 0.34rem;
  min-width: 2.65rem;
  cursor: pointer;
  text-align: center;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  z-index: 1;
}
.calendar-box.selected {
  color: black !important;
  background-color: var(--bg-selected) !important;
}
.calendar-box:hover {
  color: var(--color-hover-20);
  background-color: var(--color-hover);
}

.calendar-box.selected::after {
  bottom: -9.5px;
  left: 50%;
  border-style: solid;
  border-image: initial;
  content: " ";
  height: 0px;
  width: 0px;
  position: absolute;
  pointer-events: none;
  border-color: var(--bg-selected) rgba(255, 138, 0, 0) rgba(255, 138, 0, 0);
  border-width: 5px 7px;
  margin-left: -7px;
}

.calendar-box__week {
  font-weight: 700;
}
.calendar-box__monnth {
  font-weight: 400;
}

@media (max-width: 768px) {
  .calendar-blur {
    width: 2rem;
    height: 98%;
    top: 0;
    position: absolute;
    z-index: 2;
  }
  .calendar-blur.left {
    background: linear-gradient(90deg, var(--bg-theme), transparent);
    left: 0;
  }
  .calendar-blur.right {
    background: linear-gradient(90deg, transparent, var(--bg-theme));
    right: 0;
  }
  .calendar-wrapper {
    position: relative;
    width: 15rem;
    margin-right: 1.5rem;
    flex-grow: 1;
  }
  .calendar-container {
    overflow-x: auto;
    overflow-y: hidden;
    /* max-width: 15.188rem; */
  }
  .calendar-box:hover {
    color: white;
    background-color: transparent;
  }
}
</style>
